import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import NewUIListingCard from "../components/Card/newuilistingcard"
import SEOHeader from "../components/seo-header"
import { graphql, Link } from "gatsby"
import BlogCardCategory from "../components/blog/blog-card-category"
import { CoworkingContent } from "../components/pagecontent/coworkingcontent"
const CoworkingSpacesLocalityAll = props => {
  const { city } = props.pageContext
  const { cityslug } = props.pageContext
  const { slug } = props.pageContext
  const params = new URLSearchParams(props.location.search)
  const operatorName = params.get("operatorName") // bar
  const [spaceFilter, setspaceFilter] = useState("All")
  const [spaceSize, setspaceSize] = useState([])
  const [instantBooking, setInstantBooking] = useState("false")
  const [privateCabinFilter, setprivateCabinFilter] = useState("false")
  const [openDeskFilter, setopenDeskFilter] = useState("false")
  const [MetroNearByFilter, setMetroNearByFilter] = useState("false")
  const [DailyPassFilter, setDailyPassFilter] = useState("false")
  const [TwentyFourFilter, setTwentyFourFilter] = useState("false")
  const [LocalityList, setLocalityList] = useState([])
  const [filterStyle, setfilterStyle] = useState("User Rating")
  const [mobileFilterSecondary, setmobileFilterSecondary] = useState(false)
  const [mobileFilter, setmobileFilter] = useState(false)
  const [localityFilter, setlocalityFilter] = useState("All")
  const filter = {
    category: "Coworking",
    keyword: city,
  }
  useEffect(() => {
    if (cityslug) {
      localStorage.setItem("slug", cityslug)
    }

    if (localStorage.getItem("locality")) {
      localStorage.setItem("slug", cityslug)
      setlocalityFilter(localStorage.getItem("locality"))
    } else {
      setlocalityFilter("All")
    }
  }, [])
  function privateCabinCheck() {
    if (privateCabinFilter === "true") {
      setprivateCabinFilter("false")
    } else {
      setprivateCabinFilter("true")
      setspaceFilter("Private Cabin")
    }
  }
  function openDeskCheck() {
    if (openDeskFilter === "true") {
      setopenDeskFilter("false")
    } else {
      setopenDeskFilter("true")
      setspaceFilter("Open Desk")
    }
  }
  function MetroNearByCheck() {
    if (MetroNearByFilter === "true") {
      setMetroNearByFilter("false")
    } else {
      setMetroNearByFilter("true")
      setspaceFilter("Metro Nearby")
    }
  }
  function InstantBookingCheck() {
    if (instantBooking === "true") {
      setInstantBooking("false")
    } else {
      setInstantBooking("true")
      setspaceFilter("Instant Booking")
    }
  }

  function DailyPassCheck() {
    if (DailyPassFilter === "true") {
      setDailyPassFilter("false")
    } else {
      setDailyPassFilter("true")
      setspaceFilter("Daily Pass")
    }
  }
  function TwentyFourCheck() {
    if (TwentyFourFilter === "true") {
      setTwentyFourFilter("false")
    } else {
      setTwentyFourFilter("true")
      setspaceFilter("Twenty Four Hours")
    }
  }
  var officequery,
    metroquery,
    opendeskquery,
    privatecabinquery,
    dailypassquery,
    instantbookingquery,
    twentyfourquery = " "
  if (openDeskFilter === "true") {
    opendeskquery = "Open Desk"
  } else {
    opendeskquery = " "
  }
  if (privateCabinFilter === "true") {
    privatecabinquery = "Private Cabin"
  } else {
    privatecabinquery = " "
  }
  if (DailyPassFilter === "true") {
    dailypassquery = "Daily Pass"
  } else {
    dailypassquery = " "
  }
  if (instantBooking === "true") {
    instantbookingquery = "Instant Booking"
  } else {
    instantbookingquery = " "
  }
  if (MetroNearByFilter === "true") {
    metroquery = "Metro Nearby"
  } else {
    metroquery = " "
  }
  if (TwentyFourFilter === "true") {
    twentyfourquery = "Twenty Four Hours"
  } else {
    twentyfourquery = " "
  }
  if (
    privateCabinFilter === "false" &&
    DailyPassFilter === "false" &&
    MetroNearByFilter === "false" &&
    TwentyFourFilter === "false"
  ) {
    officequery = true
  }
  const lists = props.data.allListings.edges
  const locality = props.data.allLocalities.edges
  const LocalityListSelect = locality.map(local => {
    const localitynew = lists.filter(
      list =>
        list.node.spaceAddress === local.node.localityName &&
        list.node.dayPassAvailable === true
    )

    if (localitynew.length > 0) {
      return (
        <option value={local.node.localityName}>
          {local.node.localityName} ({localitynew.length}{" "}
          {localitynew.length > 1 ? "Options" : "Option"})
        </option>
      )
    }
  })
  const localityChange = e => {
    setlocalityFilter(e.target.value)
    if (window.history) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?locality=" +
        e.target.value
      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
  const count = props.data.allListings.totalCount
  const OfficeFilter = () => {
    useEffect(() => {
      setspaceSize(document.querySelectorAll(".listingCardPaddingNew").length)
    })
    return (
      <div className="officefiltercontainer">
        <ul className="OfficeFilter scrollmenu">
          <li>
            <p className={"LocalityBox "}>
              <select onChange={localityChange} value={localityFilter}>
                <option value="All">All Localities ({count} Options)</option>
                {LocalityListSelect}
              </select>
            </p>
          </li>
          <li>
            <a
              className={"CheckBox " + instantBooking}
              onClick={InstantBookingCheck}
            >
              Instantly Book{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + MetroNearByFilter}
              onClick={MetroNearByCheck}
            >
              Near Metro{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + privateCabinFilter}
              onClick={privateCabinCheck}
            >
              Private Cabin{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a className={"CheckBox " + openDeskFilter} onClick={openDeskCheck}>
              Desk <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + TwentyFourFilter}
              onClick={TwentyFourCheck}
            >
              24x7 <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    )
  }

  const changeFilter = filterText => {
    setmobileFilterSecondary(false)
    setfilterStyle(filterText)
    if (filterText === "Featured") {
      lists.sort(function (a, b) {
        if (a.node.isFeatured > b.node.isFeatured) {
          return -1
        }
      })
    }
    if (filterText === "PriceLow") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) < Number(b.node.priceHr)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) < Number(b.node.priceDay)) {
          return -1
        }
      })
    }
    if (filterText === "PriceHigh") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) > Number(b.node.priceDay)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) > Number(b.node.priceHr)) {
          return -1
        }
      })
    }
    if (filterText === "User Rating") {
      lists.sort(function (a, b) {
        if (Number(a.node.Rating) > Number(b.node.Rating)) {
          return -1
        }
      })
    }
    if (filterText === "Nearest") {
      lists.sort(function (a, b) {
        if (Number(a.node.pincode) > Number(b.node.pincode)) {
          return -1
        }
      })
    }
    if (filterText === "Popular") {
      lists.sort(function (a, b) {
        if (Number(a.node.bookingcount) > Number(b.node.bookingcount)) {
          return -1
        }
      })
    }
  }
  lists.sort(function (a, b) {
    if (Number(a.node.online) > Number(b.node.online)) {
      return -1
    }
  })
  const reset = () => {
    localStorage.removeItem("slug")
    localStorage.setItem("spaceType", "coworking-spaces")
    localStorage.setItem("resetLocation", true)
    window.location.reload(true)
  }
  const listsnew =
    localityFilter === "All"
      ? operatorName === "" || operatorName === null
        ? lists
        : lists.filter(list => list.node.operatorName === operatorName)
      : lists.filter(list => list.node.spaceAddress === localityFilter)
  return (
    <div>
      <SEOHeader
        title={"List of coworking space in " + city + " | GoFloaters"}
        description={
          "Easiest way to find & book on-demand coworking spaces in " +
          city +
          " Find workspaces that can help you rediscover your work mojo at coworking space near you."
        }
        socialURL={
          "https://assets.gofloaters.com/socialimage/coworking-spaces-in-" +
          cityslug +
          ".jpg"
        }
        pinterest="true"
      ></SEOHeader>
      <Layout page="city">
        <div
          style={{
            position: "fixed",
            bottom: mobileFilterSecondary ? "0px" : "-100px",
            zIndex: "10",
            width: "100%",
            // opacity: mobileFilterSecondary ? "1" : "0",
            display: mobileFilterSecondary ? "block" : "none",
            background: "#fdfdfd",
            transition: "0.5s",
            overflow: "scroll",
            overflowX: "hidden",
            boxShadow: "0px -16px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{ padding: "5px 15px", borderBottom: "1px solid #d6d6d6" }}
          >
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sort</p>
          </div>
          
          <div className="col-md-12">
            <div
              className={
                filterStyle === "User Rating"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("User Rating")
              }}
            >
              <p>
                <b>User Rating:</b> Highest first
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "Featured"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("Featured")
              }}
            >
              <p>
                <b>Featured:</b> Highly Recommended
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "Popular" ? "sortingCard active" : "sortingCard"
              }
              onClick={() => {
                changeFilter("Popular")
              }}
            >
              <p>
                <b>Popular:</b> Frequently booked
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div
              className={
                filterStyle === "PriceLow"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceLow")
              }}
            >
              <p>
                <b>Price:</b> Lowest first
              </p>
            </div>
          </div>
          <div className="col-md-2">
            <div
              className={
                filterStyle === "PriceHigh"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceHigh")
              }}
            >
              <p>
                <b>Price:</b> Highest first
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <br></br>
          <h1>
            {operatorName && localityFilter === "All"
              ? operatorName + " "
              : "Best "}
            Coworking spaces in {city}{" "}
            <a className="changeCity" onClick={reset}>
              Change City
            </a>
          </h1>
          <p style={{ fontSize: "13px" }}>
            If you are looking for the best coworking spaces in {city} then you
            have come to the right page. An average cost of a hot desk in
            {city} is in the range of Rs 250 / day.{" "}
          </p>
          <OfficeFilter></OfficeFilter>
          <br></br>
          <div class="popularscrollmenu-s">
            {/* <Link
              to={"/coworking-spaces/" + cityslug + "/all"}
              className="popularlocalityLink active"
            >
              All
            </Link>
             {locality.map(local => {
              const localitynew = lists.filter(
                list =>
                  list.node.spaceAddress === local.node.localityName &&
                  list.node.dayPassAvailable === true
              )
              if (localitynew.length > 0) {
                return (
                  <Link
                    to={
                      "/coworking-spaces/" +
                      cityslug +
                      "/" +
                      local.node.localitySlug
                    }
                    className="popularlocalityLink centerAligner"
                  >
                    {local.node.localityName}
                    {"  "}
                    <div className="countSlip centerAligner">
                      {localitynew.length}
                    </div>
                  </Link>
                )
              }
            })} */}
          </div>
          <div className="DesktopOnly">
            <div className="row alignersorting sortingCardList">
              <div className="col-md-12">
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "#2193b0",
                  }}
                >
                  SORT BY:
                </p>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "User Rating"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("User Rating")
                  }}
                >
                  <p>User Rating</p>
                  <span>Highest first</span>
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "Featured"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("Featured")
                  }}
                >
                  <p>Featured</p>
                  <span>Highly Recommended</span>
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "Popular"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("Popular")
                  }}
                >
                  <p>Popular</p>
                  <span>Frequently booked</span>
                </div>
              </div>
              {/* 
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Nearest"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Nearest")
                        }}
                      >
                        <p>Nearest</p>
                        <span>Closest to you</span>
                      </div>
                    </div> */}
             
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "PriceLow"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("PriceLow")
                  }}
                >
                  <p>Price</p>
                  <span>Lowest first</span>
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "PriceHigh"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("PriceHigh")
                  }}
                >
                  <p>Price</p>
                  <span>Highest first</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row alignersorting">
            <div className="MobileOnly col-md-12 text-right">
              <span
                onClick={() => {
                  setmobileFilterSecondary(!mobileFilterSecondary)
                }}
                className="sortNowButton"
              >
                <i
                  className="fa fa-sort-amount-asc"
                  style={{ paddingRight: "10px", cursor: "pointer" }}
                ></i>{" "}
                Sort
              </span>
            </div>
          </div>
          <div className="col-md-12 col-12">
            <p style={{ padding: "15px 0 0", color: "#7a7a7a" }}>
              {spaceSize} Spaces match your filter{" "}
            </p>
          </div>
          <div className="row">
            {listsnew.map((list, i) => {
              const listData = list.node

              if (
                listData.Facility.search(opendeskquery) > 1 &&
                listData.Facility.search(privatecabinquery) > 1 &&
                listData.Facility.search(dailypassquery) > 1 &&
                listData.Facility.search(metroquery) > 1 &&
                listData.Facility.search(twentyfourquery) > 1 &&
                listData.Facility.search(instantbookingquery) > 1
              ) {
                return (
                  <NewUIListingCard
                    key={listData.spaceId}
                    spacetype={listData.spaceType}
                    listingImg={listData.spaceImage}
                    title={listData.spaceTitle}
                    gftitle={listData.spaceGFName}
                    spaceDesc={listData.spaceDesc}
                    spaceAddress={listData.spaceAddress}
                    priceHr={listData.priceHr}
                    priceDay={listData.priceDay}
                    priceMonth={listData.priceMonth}
                    monthPassAvailable={listData.monthPassAvailable}
                    dayPassAvailable={listData.dayPassAvailable}
                    hourPassAvailable={listData.hourPassAvailable}
                    spaceId={listData.spaceId}
                    officeSpaceType={listData.officeSpaceType}
                    spaceDisplayName={listData.spaceDisplayName}
                    OriginalName={listData.OriginalName}
                    Facility={listData.Facility}
                    Slug={"/coworking-space/" + listData.slug}
                    hasCovidSafeBadge={listData.hasCovidSafeBadge}
                    online={listData.online}
                    Rating={listData.Rating}
                    needsBookingConfirmation={listData.needsBookingConfirmation}
                    type="Coworking"
                    priceperdayPreDiscount={listData.priceperdayPreDiscount}
                    priceperhrPreDiscount={listData.priceperhrPreDiscount}
                  ></NewUIListingCard>
                )
              }
            })}
          </div>
          <div className="row">
            <CoworkingContent city={city} />
          </div>
        </div>{" "}
        <br></br>
        <br></br>{" "}
        <div className="container">
          <div className="row">
            <BlogCardCategory filter={filter} />
          </div>
        </div>
        <br></br>
        <br></br>{" "}
      </Layout>
    </div>
  )
}
export default CoworkingSpacesLocalityAll
export const query = graphql`
  query CoworkingSpacesLocalityAll($city: String!) {
    allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        spaceCity: { eq: $city }
        dayPassAvailable: { eq: true }
        slug: { ne: "" }
        online: {eq: true}
      }
      sort: { fields: Rating, order: DESC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          bookingcount
          needsBookingConfirmation
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
          operatorName
        }
      }
    }
    allLocalities(
      filter: { cityName: { eq: $city } }
      sort: { fields: localityName }
    ) {
      edges {
        node {
          cityName
          cityPriority
          cityslug
          displayCity
          id
          localitySort
          localitySlug
          localityName
        }
      }
    }
  }
`
